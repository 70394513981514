<template>
  <div class="login-view">
    <BaseCard class="login-view__card">
      <h3 class="title mb-2">Log into OurForest! 🌲</h3>
      <LoginForm @success="$router.push({ name: 'Welcome' })"/>
      <p class="footer-text">Need an account? <router-link class="base-link" to="register">Sign up for OurForest</router-link></p>
      <div class="login-view__card--footer">
        If you have any questions please contact <a class="base-link" href="mailto:support@ourforest.io">support@ourforest.io</a>. We would be happy to assist you!
      </div>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import LoginForm from '@/components/LoginForm.vue';

@Options({
  title: 'Login',
  components: {
    BaseCard,
    LoginForm
  },
})
export default class Login extends Vue {

}
</script>

<style lang="scss">
.login-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
.login-view__card {
  margin-top: 80px;
  max-width: 400px;
  text-align: center;
}
.login-view__card .footer-text {
  margin-top: 24px;
  margin-bottom: 32px;
}
.login-view__card--footer {
  padding-top: 16px;
  border-top: 1px solid #DEE1E6;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: -24px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  background: #fafafa;
  text-align: left;
}
</style>