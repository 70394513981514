
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import LoginForm from '@/components/LoginForm.vue';

@Options({
  title: 'Login',
  components: {
    BaseCard,
    LoginForm
  },
})
export default class Login extends Vue {

}
